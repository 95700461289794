<template>
    <div>
        <br/>
        <b-card>
            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-info" @click="loadData()" v-b-tooltip.hover title="Reload data" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></button>
                    <button :class="{btn: true,  'btn-primary': !mode_union, 'btn-danger': mode_union}" @click="mode_union = !mode_union" v-b-tooltip.hover title="Union orders" style="margin-left: 10px"><b-icon-union></b-icon-union></button>
                    <button :class="{btn: true, 'btn-primary': !show_filter, 'btn-danger': show_filter}" @click="show_filter = ! show_filter" style="margin-left: 10px" v-b-tooltip.hover title="Filters">
                        <template v-if="!show_filter">
                            <b-icon-funnel></b-icon-funnel>
                        </template>
                        <template v-else>
                            <b-icon-funnel-fill></b-icon-funnel-fill>
                        </template>
                    </button>
                    <b-button v-if="loading" variant="primary" disabled style="margin-left: 10px">
                        <b-spinner small type="grow"></b-spinner>
                        {{$t('main.please_wait_data_is_loading')}}
                    </b-button>
                </div>
                <div class="col-sm-3">
                    <h2>{{$t('main.new_orders')}}:
                        <template v-if="new_order > 100">
                            <span class="text-danger">{{new_order}}</span>
                        </template>
                        <template v-else>
                            <span class="text-success">{{new_order}}</span>
                        </template>
                    </h2>
                </div>
                <div class="col-sm-3">
                    <h2>{{$t('main.missed_call_opp')}}:
                        <template v-if="missed_order > 100">
                            <span class="text-danger">{{missed_order}}</span>
                        </template>
                        <template v-else>
                            <span class="text-success">{{missed_order}}</span>
                        </template>
                    </h2>
                </div>

            </div>
        </b-card>
        <b-card v-if="show_filter" style="margin-top: 10px">
            <table width="100%">
                <tbody>
                <tr>
                    <th class="text-center">{{$t('main.period')}}</th>
                    <th class="text-center">{{$t('main.search_by_order_number2')}}</th>
                    <th class="text-center">{{$t('main.operator')}}</th>
                    <th class="text-center">{{$t('main.operator_opp')}}</th>
                    <th class="text-center">{{$t('main.status')}}</th>
                    <th></th>
                </tr>
                <tr>
                    <td>
                        <b-form-select v-model="filter.s_filter_time" :options="so.options_time" @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <b-form-input v-model="filter.s_filter_order_id"  type="number" @change="changeFilter()"></b-form-input>
                    </td>
                    <td>
                        <b-form-select v-model="filter.s_filter_owner" :options="so.options_owners"  @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <b-form-select v-model="filter.s_filter_operator" :options="so.options_operators"  @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <b-form-select v-model="filter.s_filter_status" :options="so.options_status"  @change="changeFilter()"></b-form-select>
                    </td>
                    <td>
                        <button class="btn btn-danger"  @click="resetFilter()"   :disabled="filter.s_filter_time == 'today' && filter.s_filter_order_id == '' && filter.s_filter_status=='new-order' && filter.s_filter_owner=='' && filter.s_filter_operator==''">{{$t('main.reset_filter')}}</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </b-card>

        <template v-if="filter.panel_datepick">
            <br/>
            <b-card>
                <div class="row" v-if="filter.panel_datepick">
                    <div class="col-sm-6">
                        <label class="mr-sm-2" for="inline-form-custom-select-pref">{{$t('main.beginning_of_period')}}: </label>
                        <b-form-datepicker
                                v-model="filter.date_min_value"
                                size="sm"
                                class="mb-2"
                                style="margin-right: 20px"
                                @input = "loadData()"
                                ></b-form-datepicker>
                    </div>
                    <div class="col-sm-6">
                        <label class="mr-sm-2" for="inline-form-custom-select-pref">{{$t('main.end_of_period')}}: </label>
                        <b-form-datepicker
                                v-model="filter.date_max_value"
                                size="sm"
                                class="mb-2"
                                @input = "loadData()"
                                ></b-form-datepicker>
                    </div>
                </div>
            </b-card>
        </template>
        <br/>
        <b-overlay :show="loading" rounded="sm">
            <b-row>
                <b-col cols="4">
                    <template v-if="pageCount > 1">
                        <b-pagination-nav
                                v-model="pageNumber"
                                :number-of-pages="pageCount"
                                base-url="#"
                                @change="goPage"
                                first-number
                                last-number
                                align="fill"
                                >
                        </b-pagination-nav>
                    </template>
                </b-col>

                <b-col style="padding-top: 5px;" class="text-left" cols="8">
                    <span style="font-size: 80%">{{$t('main.paging_show', {c: orders.length, from: filtredDataCount})}}</span>
                </b-col>
            </b-row>
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th v-if="mode_union" scope="col"></th>
                    <th scope="col"><a href="#" @click="sortcol('number')">{{$t('main.number_order')}}</a>
                        <template v-if="filter.sort_col == 'number'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('ctime')">{{$t('main.date_order')}}</a>
                        <template v-if="filter.sort_col == 'ctime'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('client')">{{$t('main.customer')}}</a>
                        <template v-if="filter.sort_col == 'client'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('phone')">{{$t('main.phone')}}</a>
                        <template v-if="filter.sort_col == 'phone'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('owner')">{{$t('main.operator')}}</a>
                        <template v-if="filter.sort_col == 'owner'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col"><a href="#" @click="sortcol('operator')">{{$t('main.operator_opp')}}</a>
                        <template v-if="filter.sort_col == 'operator'">
                            <template v-if="filter.sort_direct == 'asc'">
                                <b-icon-caret-up-fill></b-icon-caret-up-fill>
                            </template>
                            <template v-else>
                                <b-icon-caret-down-fill></b-icon-caret-down-fill>
                            </template>
                        </template>
                    </th>
                    <th scope="col">{{$t('main.status_order')}}</th>
                    <th scope="col">{{$t('main.operations')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in orders">
                    <td>{{(pageNumber-1)*size + index + 1}}</td>
                    <td v-if="mode_union"><b-form-checkbox></b-form-checkbox></td>
                    <td>{{item.number}}</td>
                    <td>{{item.ctime}}</td>
                    <td>{{item.client}}</td>
                    <td>{{item.phone}}</td>
                    <td>{{item.owner_name}}</td>
                    <td>{{item.operator}}</td>
                    <td>{{item.status}}</td>
                    <td>
                        <button class="btn btn-sm btn-success"  @click="showOrder(item)">
                            <b-icon-pencil-square></b-icon-pencil-square>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>

        </b-overlay>
        <Order
                @close="loadData()"
                ></Order>
    </div>
</template>

<script>
    import Axios from 'axios';
    import bus from "@/bus";
    import Order from "@/components/Order.vue";
    import statusForm from "@/components/statusForm.vue";

    export default {
        name: 'OPP',
        components: {
            Order,
            statusForm
        },

        data() {
            return {
                orders: [],
                new_order: 0,
                missed_order: 0,
                loading: false,
                pageNumber: 1,
                filtredDataCount: 0,
                show_filter: true,
                mode_union: false,
                filter: {
                    date_min_value:     '',
                    date_max_value:     '',

                    panel_datepick:     false,
                    panel_search:       false,

                    s_filter_time:      'today',
                    s_filter_custom:    'none',
                    s_filter_owner:     '',
                    s_filter_operator:  '',
                    s_filter_status:    'new-order',
                    s_filter_order_id:  '',
                    sort_col:           'client',
                    sort_direct:        'desc'
                },
                so: {
                    options_time: [
                        {value: 'all', text: this.$t('main.show_all')},
                        {value: 'today', text: this.$t('main.today')},
                        {value: 'yesterday', text: this.$t('main.yesterday')},
                        {value: 'days7', text: this.$t('main.in_7_days')},
                        {value: 'days30', text: this.$t('main.current_month')},
                        {value: 'manual', text: this.$t('main.period')},
                    ],
                    options_status: [],
                    options_operators: [],
                    options_owners: []
                }
            }
        },

        metaInfo() {
            return {
                title: this.$t('opp.title')
            }
        },

        mounted() {
            this.filter.date_min_value   = new Date().toISOString().slice(0, 10);
            this.filter.date_max_value   = new Date().toISOString().slice(0, 10);

            bus.$emit('set-header', this.$t('opp.title'));

            if (typeof localStorage.filter_opp !== 'undefined') {
                this.filter = JSON.parse(localStorage.filter_opp);
            }
            this.loadData();
        },

        props: {
            size: {
                type: Number,
                        required: false,
            default: 20
            }
        },

        methods: {
            showOrder(data) {

                if (data.operator_id == 0 && data.set_opp) {
                    Axios
                            .post(this.prefix_url + '/api/opp?ts=' + Date.now(), {
                                method:     'set_opp_manager',
                                ether_id:   data.etherid,
                                order_id:   data.orderid
                            }).then(response => {
                        bus.$emit('view-message', {
                                message: this.$t('opp.you_have_been_assigned_opp_manager_for_order', {number: data.number}),
                                variant: 'success'
                            });
                    bus.$emit('show-order', {ether_id: data.etherid, order_id: data.orderid});
                    this.loadData();
                });
            }else{
                bus.$emit('show-order', {ether_id: data.etherid, order_id: data.orderid});
            }
        },

        sortcol(col){
            console.log('sort', col);
            if (col == 'number') {
                if (this.filter.sort_col != 'number') {
                    this.filter.sort_col      = 'number';
                    this.filter.sort_direct   = 'desc';
                }else{
                    if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                }
            }else if (col == 'ctime') {
                if (this.filter.sort_col != 'ctime') {
                    this.filter.sort_col      = 'ctime';
                    this.filter.sort_direct   = 'asc';
                }else{
                    if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                }
            }else if (col == 'client') {
                if (this.filter.sort_col != 'client') {
                    this.filter.sort_col      = 'client';
                    this.filter.sort_direct   = 'desc';
                }else{
                    if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                }
            }else if (col == 'phone') {
                if (this.filter.sort_col != 'phone') {
                    this.filter.sort_col      = 'phone';
                    this.filter.sort_direct   = 'desc';
                }else{
                    if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                }
            }else if (col == 'operator') {
                if (this.filter.sort_col != 'operator') {
                    this.filter.sort_col      = 'operator';
                    this.filter.sort_direct   = 'desc';
                }else{
                    if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                }
            }else if (col == 'owner') {
                if (this.filter.sort_col != 'owner') {
                    this.filter.sort_col      = 'owner';
                    this.filter.sort_direct   = 'desc';
                }else{
                    if (this.filter.sort_direct == 'asc') this.filter.sort_direct = 'desc';else this.filter.sort_direct = 'asc';
                }
            }
            this.loadData();
        },

        loadData() {
            this.loading = true;
            Axios
                    .get(this.prefix_url + '/api/opp?ts=' + Date.now()+'&data='+JSON.stringify(this.filter)+'&page='+(this.pageNumber-1)+'&limit='+this.size)
                    .then(response => {
                        this.orders = response.data.orders;
                        this.so.options_owners = response.data.owners;
                        this.so.options_operators = response.data.operators;
                        this.so.options_status  = response.data.statuses;
                        this.filtredDataCount   = response.data.count;
                        this.new_order = response.data.new_order;
                        this.missed_order = response.data.missed_order;

                        this.loading = false;
            });
        },

    resetFilter() {
        this.filter.s_filter_time        = 'today';
        this.filter.s_filter_order_id    = '';
        this.filter.s_filter_owner       = '';
        this.filter.s_filter_operator    = '';
        this.filter.s_filter_status      = 'new-order';
        this.filter.panel_datepick       = false;
        this.filter.panel_search         = false;

        this.changeFilter();
    },

    changeMinDate(){
        console.log('min', this.datepick_min_value, this.datepick_max_value);
        let min_value = new Date(this.datepick_min_value),
                max_value = new Date(this.datepick_max_value);
        if (min_value.getTime() > max_value.getTime()) {
            bus.$emit('view-message', { message: this.$t('main.start_date_period_cannot_be_later_than_the_end_date_period'), variant: 'danger' });
            this.datepick_min_value = this.datepick_max_value;
        }
        this.loadData();
    },

    changeMaxDate(){
        console.log('max', this.datepick_min_value, this.datepick_max_value);
        let min_value = new Date(this.datepick_min_value),
                max_value = new Date(this.datepick_max_value);
        if (max_value.getTime() < min_value.getTime()) {
            bus.$emit('view-message', { message: this.$t('main.end_date_period_cannot_be_earlier_than_the_start_date_period'), variant: 'danger' });
            this.datepick_max_value = this.datepick_min_value;
        }
        this.loadData();
    },

    changeFilter(){
        if (this.filter.s_filter_custom === 'none') {
            this.filter.panel_datepick = this.filter.s_filter_time === 'manual';
            this.loadData();
        }
        if (this.pageNumber !== 1) {
            this.pageNumber = 1;
            this.loadData();
        }
    },

    goPage(n) {
        this.pageNumber = n;
        this.loadData();
    },

    addStatus(data) {
        this.user_rules.push({ id: data.id, name: data.name });
    },

    delStatus(data) {
        let index = this.user_rules.map(function (item) {
            return item.id;
        }).indexOf(data.id);

        this.user_rules.splice(index, 1);
    }
    },

    computed : {
        rule_order_hopshop: function (){return this.$store.getters.getRule('order') },
        rule_order_zargar: function (){return this.$store.getters.getRule('orderzargar') },
        rule_order_lots: function (){return this.$store.getters.getRule('orderlots') },
        rule_order_search: function (){return this.$store.getters.getRule('orderSearch') },
        rule_order_create_ticket: function (){return this.$store.getters.getRule('ticketCreate') },

        pageCount() {
            let l = this.filtredDataCount,
                    s = this.size;
            if (this.filtredDataCount===0) return 1;

            return Math.ceil(l / s);
        },

        current_phone: {
            get () { return this.$store.getters.getCurrentPhone; },
            set(value) { this.$store.dispatch('setCurrentPhone', value); }
        },
        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
    },

    watch: {
        datepick_max_value: function(val){
            this.changeMaxDate();
        },

        datepick_min_value: function(val){
            this.changeMinDate();
        },
        filter: {
            handler(val)
            {
                let j = JSON.stringify(this.filter);

                localStorage.filter_opp = j;
            },
            deep: true
        }
    }
    }
</script>