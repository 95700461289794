<template>
  <div>
    <div class="modal-body">
    <b-overlay :show="loading_ticket" rounded="sm">
      <template>
      <div v-if="ticket_id">
        <b-row>
          <b-col cols="4">{{$t('helpdesk.owner_tr')}}</b-col>
          <b-col cols="8">
            <div class="input-group" >

              <input v-if="so.operators.length == 0" disabled="disabled" class="form-control" v-model="ticket.owner_name">
              <b-form-select v-else v-model="ticket.owner" :options="so.operators" :disabled="ticket.closed"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <br/>
      </div>
      <b-row>
        <b-col cols="4">{{$t('helpdesk.phone_number_tr')}}</b-col>
        <b-col cols="8">
          <div class="input-group" >
            <input type="phone" :disabled="!ticket.editable || ticket.closed" class="form-control" v-model="ticket.customer_phone" autocomplete="off" ref="order_phone">
            <div class="input-group-append" >
                  <button v-if="current_phone.length > 0" class="btn btn-success " type="button" id="button-addon2" @click="ticket.customer_phone = current_phone;current_phone = ''">{{current_phone}}</button>
            </div>
            <div class="input-group-append" v-if="ticket.editable && !ticket.closed" >
              <button class="btn btn-info" :disabled="!ValidPhone(ticket.customer_phone)" @click="loadTicket_by_phone(ticket.customer_phone)">check</button>
            </div>
          </div>
        </b-col>
      </b-row>
      <br/>
      </template>
      <div v-if="ticket_type === 'order'">
        <b-row>
          <b-col cols="4">{{$t('helpdesk.entity.type')}}</b-col>
          <b-col cols="8">
            <div class="input-group" >
              <b-form-select v-model="ticket.type" :options="so.types_available"></b-form-select>
            </div>
          </b-col>
        </b-row>
        <br/>

      </div>
      <div v-if="ticket_type === 'delivery'">
      <b-row >
        <b-col cols="4">Retail ID</b-col>
        <b-col cols="8">
          <div class="input-group">
            <input type="text" class="form-control" v-model="ticket.order_id" autocomplete="off" ref="order_id" :disabled="ticket.customer_phone === '' || !ticket.editable || ticket.closed" @change="so.options_order_id = []">
            <div class="input-group-append" v-if="ticket.order_id.length > 0 && so.options_order_id.length < 2 && ticket.editable && !ticket.closed" >
                  <button class="btn btn-info" @click="loadTicket_by_order(ticket.order_id)">check</button>
            </div>
            <div class="input-group-append" v-if="so.options_order_id.length > 1 && ticket.editable && !ticket.closed">
              <b-form-select v-model="ticket.select_order_id" :options="so.options_order_id" @input="selectOrder"></b-form-select>
            </div>
          </div>
        </b-col>
      </b-row>
        <br />
      </div>
      <div v-if="ticket_type === 'product'">
      <b-row>
        <b-col cols="4">ID</b-col>
        <b-col cols="8">
          <div class="input-group">
            <input type="text" class="form-control" v-model="ticket.order_id" autocomplete="off"  :disabled="ticket.customer_phone === '' || !ticket.editable || ticket.closed" @change="so.options_order_id = []">
            <div class="input-group-append" v-if="ticket.order_id.length > 0 && so.options_order_id.length < 2 && ticket.editable && !ticket.closed" >
                  <button class="btn btn-info" @click="loadTicket_by_order(ticket.order_id)">check</button>
            </div>
            <div class="input-group-append" v-if="so.options_order_id.length > 1">
              <b-form-select v-model="ticket.select_order_id" :options="so.options_order_id" @input="selectOrder"></b-form-select>
            </div>
          </div>
        </b-col>
      </b-row>
        <br/>
      </div>

      <div v-if="ticket_type === 'product'">
       <b-row >
        <b-col cols="4">{{$t('helpdesk.entity.site')}}</b-col>
        <b-col cols="8">
          <b-form-select plain :disabled="!ticket.editable || ticket.closed" v-model="ticket.site" :options="so.options_site" disabled="disabled"></b-form-select>
        </b-col>
      </b-row>
      <br />
      </div>
      <div v-if="ticket_type === 'delivery'">
      <b-row>
        <b-col cols="4">{{$t('helpdesk.entity.date_order_created')}}</b-col>
        <b-col cols="8">
          <b-form-datepicker
              v-model="ticket.time_delivery_order_created"
              size="sm"
              class="mb-2"
              style="margin-right: 20px"
              :disabled="!ticket.editable || ticket.closed"

          ></b-form-datepicker>        </b-col>
      </b-row>
      <br/>
        <b-row>
          <b-col cols="4">{{$t('helpdesk.entity.date_order_complete')}}</b-col>
          <b-col cols="8">
            <b-form-datepicker
                v-model="ticket.time_delivery_complete"
                size="sm"
                class="mb-2"
                style="margin-right: 20px"
                :disabled="!ticket.editable || ticket.closed"
            ></b-form-datepicker>
          </b-col>
        </b-row>
      <br/>
      </div>
      <div v-if="ticket_type !== 'order'">
      <b-row>
        <b-col cols="4">{{$t('helpdesk.entity.costumer_name')}}</b-col>
        <b-col cols="8">
          <input type="text" class="form-control" v-model="ticket.customer_name" autocomplete="off" ref="name" :disabled="ticket.order_id === '' || !ticket.editable || ticket.closed ">
        </b-col>
      </b-row>
      <br/>
          <b-row>
              <b-col cols="4">{{$t('main.region')}}</b-col>
              <b-col cols="8">
                  <input type="text" class="form-control" v-model="ticket.region" autocomplete="off" ref="name" :disabled="ticket.order_id === '' || !ticket.editable || ticket.closed ">
              </b-col>
          </b-row>
          <br/>
          <b-row>
              <b-col cols="4">{{$t('main.address')}}</b-col>
              <b-col cols="8">
                  <input type="text" class="form-control" v-model="ticket.address" autocomplete="off" ref="name" :disabled="ticket.order_id === '' || !ticket.editable || ticket.closed ">
              </b-col>
          </b-row>
          <br/>
      </div>
      <div v-if="ticket_type === 'product'">
      <b-row>
        <b-col cols="4">{{$t('helpdesk.entity.products')}}</b-col>
        <b-col cols="8">
          <b-form-select v-model="ticket.products" :options="ticket.options_products" multiple :select-size="4" :disabled="ticket.order_id === '' || !ticket.editable || ticket.closed"></b-form-select>
        </b-col>
      </b-row>
      <br/>
      </div>
      <div v-if="ticket_type !== 'order' ">
      <b-row>
        <b-col cols="4">{{$t('helpdesk.status_tr')}}</b-col>
        <b-col cols="8">
          <b-form-select plain v-model="ticket.status" :options="so.options_status" :disabled="ticket.order_id === '' || !ticket.editable || so.options_status.length === 0"></b-form-select>
        </b-col>
      </b-row>
      <br/>
      </div>
      <div v-if="ticket_type === 'product'">
      <template v-if="ticket.site === 'zargarshop'">
        <b-row>
          <b-col cols="4">{{$t('helpdesk.entity.zargar_status')}}</b-col>
          <b-col cols="8">
            <b-form-select v-model="ticket.status_zargar" :options="so.options_status_zargar" :disabled="!ticket.editable || ticket.closed" ></b-form-select>
          </b-col>
        </b-row>
        <br/>
      </template>
      </div>
      <b-row>
        <b-col cols="4">{{$t('helpdesk.entity.comment')}}</b-col>
        <b-col cols="8">
          <b-form-textarea
              v-model="ticket.comment"
              rows="3"
              max-rows="6"
              :disabled="!ticket.editable"
          >
          </b-form-textarea>
        </b-col>
      </b-row>
      <template v-if="ticket_id">
      <br />
        <b-card
            :header="$t('helpdesk.entity.history')"
            header-tag="header"
        >
          <b-card-text>
            <b-list-group>
              <b-list-group-item v-if="ticket.comment_history.length" v-bind:key="item[0]" v-for="(item) in ticket.comment_history" class="" >
                <p>{{item[3]}} | {{item[1]}}</p>
                <p>{{item[2]}}</p>
              </b-list-group-item>
              <b-list-group-item>
                <p>{{$t('helpdesk.entity.created_ticket_history_text')}} {{ticket.date_created}}</p>
              </b-list-group-item>
              <b-list-group-item v-if="ticket.done">
                <p>{{ $t('helpdesk.entity.closed_text') }} {{ticket.date_created}}</p>
              </b-list-group-item>
            </b-list-group>
          </b-card-text>
        </b-card>
      </template>
    </b-overlay>
    </div>
    <footer class="modal-footer  ">
      <!--b-button v-if="(ticket_id && rule_supervisor && ticket.editable) && !ticket.closed" style="margin-left: 10px" class="float-left btn btn-danger" @click="changeGlobalStatus()">{{$t('helpdesk.entity.close_action')}}</b-button-->
<!--      <b-button v-if="(ticket_id && rule_supervisor && ticket.editable) && !ticket.closed" style="margin-left: 10px" class="float-left btn btn-danger" @click="changeGlobalStatus()">Удалить жалобу</b-button>-->
      <!--b-button v-else-if="(ticket_id && rule_supervisor && ticket.closed)" style="margin-left: 10px" class="float-left btn btn-danger" @click="changeGlobalStatus()">{{$t('helpdesk.entity.open_action')}}</b-button-->
      <button v-if="ticket_id && !ticket.closed" class=" btn btn-info float-left mr-auto" @click="loadTicketData()" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></button>
      <p v-if="ticket.closed" class="float-left mr-auto" style="color: grey">{{$t('helpdesk.entity.closed_text')}}</p>
      <b-button style="margin-left: 10px" class="float-right btn btn-success" @click="save_form_create_ticket()" :disabled="!ValidPhone(ticket.customer_phone) || ticket.status === '' || ticket.comment === ''">
        {{ $t('helpdesk.entity.save_ticket') }}</b-button>
      <b-button style="margin-left: 10px" class="float-right btn" @click="exitModal()">{{$t('helpdesk.entity.close_modal')}}</b-button>

    </footer>

  </div>
</template>


<script>
    import Axios from 'axios';
    import bus from "@/bus";
    import Vue from "vue";
    import th from "vue2-datepicker/locale/es/th";
    import DatePicker from "vue2-datepicker";
    export default {
        name: 'HelpDeskEntity',
      components: {DatePicker},
        data() {
            return {
              data: [],
              loading_ticket: false,
              so: {
                options_site: [
                  { value: '', text: '' },
                  { value: 'hopshop-uz', text: 'HopShop' },
                  { value: 'hopshopuz', text: 'HopShop' },
                  { value: 'zargarshop', text: 'Zargar' }
                ],
                options_status: [],
                options_order_id: [],
                options_status_zargar: [
                  { value: '', text: '' },
                  { value: "Uzukni o'lchami to'g'ri kelmadi", text: "Uzukni o'lchami to'g'ri kelmadi" },
                  { value: "Taqinchoq qoplamasini rangi o'chib ketdi", text: "Taqinchoq qoplamasini rangi o'chib ketdi" },
                  { value: "Mahsulot yoqmadi", text: "Mahsulot yoqmadi" },
                  { value: "Qulfi ishlamayapti", text: "Qulfi ishlamayapti" },
                  { value: "Toshi tushib qoldi/sinib qoldi", text: "Toshi tushib qoldi/sinib qoldi" },
                  { value: "Taqinchoq brak (singan tirnalgan yoki dog'lar bor)", text: "Taqinchoq brak (singan tirnalgan yoki dog'lar bor)" },
                  { value: "Probasi yo'q	dumal chto zoloto", text: "Probasi yo'q	dumal chto zoloto" },
                  { value: "Sertifikati yo'q", text: "Sertifikati yo'q" },
                  { value: "Mijoz toshning haqiqiy ekanligiga shubhalanyapti", text: "Mijoz toshning haqiqiy ekanligiga shubhalanyapti" },
                  { value: "Boshqa mahsulot yetkazib berildi", text: "Boshqa mahsulot yetkazib berildi" },
                  { value: "Dostavkaga shikoyat", text: "Dostavkaga shikoyat" },
                  { value: "Kuryerga shikoyat", text: "Kuryerga shikoyat" },
                  { value: "Quti yo'q", text: "Quti yo'q" },
                  { value: "Zakazda mahsulot yetishmayapti", text: "Zakazda mahsulot yetishmayapti" },
                  { value: "Noto'g'ri narx", text: "Noto'g'ri narx" },
                  { value: "Mahsulot tog'risida noto'g'ri ma'lumot berilgan (metal, toshi, og'irligi va hokazo)", text: "Mahsulot tog'risida noto'g'ri ma'lumot berilgan (metal, toshi, og'irligi va hokazo)" },
                  { value: "Sov'ga qo'shilmagan", text: "Sov'ga qo'shilmagan" },
                  { value: "Sklad mahsulotni qo'shmagan", text: "Sklad mahsulotni qo'shmagan" }
                ],
                options_order_retail_id: [],
                types_available: [
                  {value: '', text: this.$t('helpdesk.entity.choose_type_ticket'), disabled: true},
                  {value: 'product', text: this.$t('helpdesk.entity.select_type_product') },
                  {value: 'delivery', text: this.$t('helpdesk.entity.select_type_delivery') }
                ],
                operators:                      []
              },
              ticket: {
                customer_phone:                 '',
                order_id:                       '',
                owner_name:                     '',
                owner:                          '',
                select_order_id:                '',
                editable:                       false,
                site:                           '',
                time_delivery_complete :        '',
                time_delivery_order_created :   '',
                comment_history:                [],
                region:                         '',
                address:                        '',
                products:                       [],
                customer_name:                  '',
                type:                           '',
                possibly_name:                  '',
                status:                         '',
                status_zargar:                  '',
                status_delivery:                '',
                comment:                        '',
                date_created:                   ''
              },
              misc: {
                comment_compare: undefined
              },
              show_status_zargar:     false,
            }
        },
        props: {
            ticket_id: {
              type: String,
              required: false,
              default: ''
            },
            ticket_type: {
              type: String,
              required: false,
          },

        },

        mounted() {
          if (this.ticket_id) this.loadTicketData()
          else {
            this.reset_form_create_ticket()
            this.loadAvailableStatuses()
          }
        },

        methods: {
          exitModal () {
            if (this.ticket_id) this.$bvModal.hide('bv-modal-ticket-edit');
            else this.$bvModal.hide('bv-modal-ticket')
          },

          collectPayload () {
            let obj = {}

            if (this.ticket.type === '') this.ticket.type = this.ticket_type;
            if (this.ticket.site !== '') obj['site'] = this.ticket.site;
            if (this.ticket.owner !== '') obj['owner'] = this.ticket.owner;
            if (this.ticket_type === 'delivery') {
              obj['time_delivery_order_created'] = this.ticket.time_delivery_order_created;
              obj['time_delivery_complete'] = this.ticket.time_delivery_complete;
            }

            else if (this.ticket_type === 'product') {
              obj['products'] = [this.ticket.products];
              obj['options_products'] = [this.ticket.options_products];
              if (this.ticket.status_zargar) obj['status_zargar'] = this.ticket.status_zargar;

            }

            if (this.ticket_type !== 'order') {
              obj['order_id'] = this.ticket.order_id;
              obj['customer_name'] = this.ticket.customer_name;
              obj['region'] = this.ticket.region;
              obj['address'] = this.ticket.address;
              obj['status'] = this.ticket.status;
            }
            if (this.misc.comment_compare !== this.ticket.comment) obj['comment_ticket'] = this.ticket.comment;
            return {
              ...{type_ticket: this.ticket_type,
                customer_phone: this.ticket.customer_phone
               },
              ...obj
            }
          },

          save_form_create_ticket(){
            let payload = {};
            if (this.ticket_id) {
              payload['method'] = 'change';
              payload['ticket_id'] = this.ticket_id;
            } else {
              payload['method'] = 'save';
            }
            let ticket_response = '';

            payload['data'] = this.collectPayload();

            let url = this.prefix_url +
                "/api/helpdesk?ts="+Date.now();
            Axios
                .post(url, {...payload})
                .then(response => {
                  ticket_response = response.data;
                  if (ticket_response.success !== true)
                    bus.$emit('view-message', { message: (this.$t('main.error_toast') + " "+ ticket_response.message), variant: 'danger' });
                  else {
                    if (!this.ticket_id) {this.exitModal();
                    bus.$emit('update-data-helpdesk');
                  }
                  else {this.loadTicketData(); bus.$emit('update-data-helpdesk')}}
                });
          },

          loadAvailableStatuses () {
            let statuses = []
            if (this.ticket.status !== '') statuses.push (this.ticket.status)
            let url = this.prefix_url +
                "/api/helpdesk?ts="+Date.now()
                + '&type=get_statuses&type_ticket='+this.ticket_type;
            Axios
                .get(url)
                .then(response => {
                  if (response.data.statuses) statuses.push (...response.data.statuses);
                  this.so.options_status = [...new Set(statuses)];
                });
          },

          reset_form_create_ticket(){
            this.ticket.customer_phone              = '';
            this.ticket.order_id                    = '';
            this.ticket.select_order_id             = '';
            this.ticket.site                        = '';
            this.ticket.products                    = [];
            this.ticket.comment_history             = []
            this.ticket.customer_name               = '';
            this.ticket.region                      = '';
            this.ticket.address                     = '';
            this.ticket.time_delivery_order_created = '';
            this.ticket.time_delivery_complete      = '';
            this.ticket.possibly_name               = '';
            this.ticket.loaded                      = false;
            this.ticket.status                      = '';
            this.ticket.editable                    = true;
            this.ticket.status_zargar               = '';
            this.ticket.comment                     = '';
            this.operators                          = [];
            Vue.set(this.so, 'options_order_id',  []);
          },

          selectOrder(){
            this.ticket.order_id = this.ticket.select_order_id;
            this.loadTicket_by_order(this.ticket.order_id);
          },

          loadTicketData(){
            this.loading_ticket = true
            // загрузка данных с backend
                Axios
                    .get(this.prefix_url +  "/api/helpdesk?ts="+Date.now() +
                        '&type=info' + '&ticket_id=' + this.ticket_id)
                    .then(response => {
                      if (!response.data.success) {
                        bus.$emit('view-message', { message: (this.$t('main.error_toast') + ' ' + (response.data.message ? ': ' + response.data.message : '')), variant: 'danger' });
                        this.exitModal()
                        return;
                      }

                      this.ticket = response.data.ticket;
                      this.so.operators =   response.data.operators;
                      if (!this.ticket_type) this.ticket_type = response.data.ticket.type_ticket;
                      this.misc.comment_compare = response.data.ticket.comment
                      let statuses = []

                      this.ticket.select_order_id = this.ticket.order_id;

//                      if (this.ticket_type === 'product') if(response.data.ticket.products.length>0) this.loadTicket_by_order(this.ticket.order_id)

                      if (response.data.statuses) statuses.push (...response.data.statuses);
                      this.so.options_status = [...new Set(statuses)];

                      this.loading_ticket = false
                });
          },

          changeGlobalStatus () {
            let dialog;
            if (!this.ticket.closed) dialog = confirm("Вы уверены что хотите закрыть тикет?")
            else dialog = true;
            if (dialog === false)
              bus.$emit('view-message', { message: (this.$t('main.canceled_toast')), variant: 'info' });
            else {
            let url = this.prefix_url +
                "/api/helpdesk?ts="+Date.now();
            Axios
                .post(url, {
                  method: 'change_global_status',
                  ticket_id: this.ticket_id,
                  action: (this.ticket.closed) ? 'open' : 'close'
                })
                .then(response => {

                  if (response.data.success !== true)
                    bus.$emit('view-message', { message: this.$t('main.error_toast'+" " + (response.data.message ? ': ' + response.data.message : '')), variant: 'danger' });
                  else {
                    bus.$emit('view-message', { message: this.$t('helpdesk.entity.success_global_closed'), variant: 'primary'});
                    this.exitModal();
                    bus.$emit('update-data-helpdesk');

                  }
                })}
          },

          loadTicket_by_phone(phone){
            this.loading_ticket = true;
            let url = this.prefix_url + "/api/helpdesk?ts="+Date.now()+'&type=load_by_phone&phone='+phone;

            Axios
                .get(url)
                .then(response => {
                  if (response.data.success) {
                    if (response.data.data.length > 1) {
                      this.so.options_order_id = response.data.data;
                      this.so.options_order_id.unshift({value: '', text: '- select -'});
                    } else if (response.data.data.length === 0) {
                      this.ticket.order_id = '';
                      bus.$emit('view-message', {message: this.$t('helpdesk.not_found_orders_by_ticket'), variant: 'danger'});
                    } else if (response.data.data.length === 1) {
                      this.ticket.order_id = response.data.data[0].value;
                      this.so.options_order_id = [];
                      this.loadTicket_by_order(this.ticket.order_id);
                    }else{
                      bus.$emit('view-message', {message: this.$t('helpdesk.failed_to_find_orders_by_ticket'), variant: 'danger'});
                    }
                  }
                  this.loading_ticket    = false;
                });
          },

          loadTicket_by_order(order_id){
            this.loading_ticket = true;
            let url = this.prefix_url + "/api/helpdesk?ts="+Date.now()+'&type=load_by_order&order='+order_id;

            Axios
                .get(url)
                .then(response => {
                  if (response.data.success) {
                    this.ticket.site                = response.data.site;
                    this.ticket.customer_name       = response.data.name;
                    this.ticket.options_products    = response.data.products;
                    this.ticket.region              = response.data.region;
                    this.ticket.address              = response.data.address;
                    this.ticket.time_delivery_order_created = response.data.ctime;
                    this.ticket.time_delivery_complete = response.data.dtime;
                    this.loading_ticket             = false;
                  } else {
                    bus.$emit('view-message', { message: this.$t('helpdesk.order_data_not_found'), variant: 'danger' });
                    this.loading_ticket = false;
                  }
                });
          },

          ValidPhone(phone) {
            // let str = phone.replace(/\D/g, '');
            var re = /^\d{9}$/;
            return re.test(phone);
          },

            round(value, decimals) {
                return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
            },

            back(){
                this.$router.back();
            },
        },

        computed: {
          th() {
            return th;
          },

          rule_admin: function (){return this.$store.getters.getRule('tv_admin') },
          rule_supervisor: function (){return this.$store.getters.getRule('supervisorproductticket') },
          current_phone: {
            get () { return this.$store.getters.getCurrentPhone; },
            set(value) { this.$store.dispatch('setCurrentPhone', value); }
          },

          prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }
        }
    }
</script>