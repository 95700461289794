<template>
    <div>
        <br/>
	    <p style="padding-left: 10px"><b>№ Doc:</b> {{ ether.number}}, <b>Channel:</b> {{ ether.channel}}, <b>From plan:</b> {{ ether.plan_from}}, <b>To plan:</b> {{ ether.plan_to}}, <span v-if="ether.fact_from!=''"><b>From fact:</b> {{ ether.fact_from}},</span> <span v-if="ether.fact_to!=''"><b>To fact:</b> {{ ether.fact_to}},</span> <b>Editor:</b> {{ ether.editor}}, <b>Presenter:</b> {{ ether.presenter}}, <b>Lineuper:</b> {{ ether.lineuper}}</p>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-2">
                                <nobr>
                                <button :disabled="!rule_tv_write" v-if="!ether.readonly && ether.fact_from=='' && flagStartEther" type="button" class="btn btn-success" @click="start_ether(ether_id)">{{$t('tv.start_new_broadcast')}}</button>
                                <button disabled v-if="!ether.readonly && ether.fact_from=='' && !flagStartEther" type="button" class="btn btn-success">{{$t('tv.waiting_broadcast_start')}}</button>

                                <button :disabled="!rule_tv_write" v-if="!ether.readonly && ether.fact_from!='' && ether.fact_to=='' && flagStopEther" type="button" class="btn btn-danger" @click="finish_ether(ether_id)">{{$t('tv.end_broadcast')}}</button>
                                <button disabled v-if="!ether.readonly && ether.fact_from!='' && ether.fact_to=='' && !flagStopEther" type="button" class="btn btn-danger">{{$t('tv.waiting_end_broadcast')}}</button>

                                <button v-if="rule_tv_write && !ether.readonly && ether.fact_from=='' && ether.fact_to==''" type="button" class="btn btn-info" @click="reload_ether()" style="margin-left: 10px"><b-icon-arrow-clockwise></b-icon-arrow-clockwise></button>
				                <h2 v-if="!ether.readonly && ether.fact_from!='' && ether.fact_to!=''">{{$t('tv.broadcast_over')}}</h2>
                                </nobr>
                            </div>
                            <div class="col-sm-3">
                        	    <h2>Amount: {{totalEther | formatNumber}} {{currency}}</h2>
                            </div>
                            <div class="col-sm-3">
                                <h2>Number of sales: {{ number_sales }}</h2>
                            </div>
                            <div class="col-sm-2">
                                <h2>Number of calls: {{ number_calls }}</h2>
                            </div>
                            <div class="col-sm-2">
                                <h2>Points: {{ totalPointsLot }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
	<div class="row">
	    <table class="lots">
		<thead>
		<tr>
		    <th>LOT</th>
		    <th>Product</th>
		    <th>SKU</th>
		    <th>Name</th>
		    <th>Description</th>
		    <th v-if="rules.ceo">COG</th>
		    <th v-if="rule_tv_admin">Min price</th>
            <th v-if="!rule_supervisor">Target</th>
		    <th v-if="!rule_supervisor">Our price</th>
		    <th>Final price</th>
		    <th v-if="rules.ceo">Inv-COG</th>
            <th>History Price</th>
		    <th>Live</th>
            <th>Return</th>
            <th>Points Lot</th>
		    <th>Qty start</th>
		    <th>Qty reserved</th>
		    <th>Qty sold/ Expected</th>
		    <th>Qty left</th>
            <th>Over Sales</th>
		    <th v-if="rules.ceo">Margin, %</th>
		    <th v-if="rules.ceo">Callers old+new</th>
		    <th v-if="rules.ceo">Old Callers</th>
		    <th v-if="rules.ceo">New Callers</th>
		    <th v-if="rules.ceo">Calls total</th>
		    <th v-if="rules.ceo">Old Calls</th>
		    <th>New Calls</th>
		    <th v-if="rules.ceo">old+new # of calls</th>
		    <th v-if="rules.ceo">Turnover</th>
		    <th v-if="rules.ceo">Sum Inv+COG</th>
		    <th>Start time</th>
		    <th>Finish time</th>
            <th>Lot amount</th>
            <th>PPM</th>
		    <th>LOT N for customer</th>
            <th v-if="rule_tv_write">VMix Control</th>
		</tr>
		</thead>
		<tbody>
		<tr v-for="(result, index) in lots" :key="result.id" :class="{'row-active-ether': result.id == active_lot}">
		    <td>{{result.pos}}</td>
		    <td><ExpandImage thumbnail fluid :src="result.image" width="90"></ExpandImage></td>
		    <td><nobr>{{result.article}}</nobr><br/>
                <b-button variant="info" size="sm" @click="showOfferComment(result)">
                    <b-icon-chat-dots></b-icon-chat-dots> <b-badge variant="light">{{result.count_full_comment || 0}}</b-badge>
                </b-button>
            </td>
		    <td>{{result.name}}</td>
		    <td>{{result.description}}</td>
		    <td v-if="rules.ceo">{{result.cog}}</td>
		    <td v-if="rule_tv_admin" class="text-right at-padding">{{result.min_price | formatNumber }}</td>
            <td v-if="!rule_supervisor" class="text-right at-padding">{{result.target | formatNumber }}</td>
		    <td v-if="!rule_supervisor" class="text-right at-padding">{{result.our_price | formatNumber }}</td>
		    <td>
			<template v-if="update_price != result.id">
			    <button class="btn btn-warning"><b>{{result.final_price | formatNumber }}</b></button>
			    <template v-if="result.id == active_lot">
				<br/>
				<b-button
                    :disabled="!rule_tv_write"
				    class="op"
				    variant="primary"
				    @click="update_price = result.id"
				>
				    <b-icon-pencil-square></b-icon-pencil-square>
				</b-button>
			    </template>
			</template>
			<template v-else>
			    <input type="number" :min="result.min_price" v-model="result.final_price" @change="changeFinalPrice(result)"><br/>
			    <b-button :disabled="(result.final_price-result.min_price)<10000" size="sm" variant="primary" class="op" @click="minusPrice(result, 10000)">-10K</b-button>
			    <b-button :disabled="(result.final_price-result.min_price)<20000" size="sm" variant="primary" class="op" @click="minusPrice(result, 20000)">-20K</b-button>
			    <b-button :disabled="(result.final_price-result.min_price)<50000" size="sm" variant="primary" class="op" @click="minusPrice(result, 50000)">-50K</b-button>
			    <b-button :disabled="(result.final_price-result.min_price)<100000" size="sm" variant="primary" class="op" @click="minusPrice(result, 100000)">-100K</b-button>
                <b-button :disabled="(result.final_price-result.min_price)<200000" size="sm" variant="primary" class="op" @click="minusPrice(result, 200000)">-200K</b-button>
			    <template v-if="rule_tv_admin">
                    <br/>
                    <span>min: {{result.min_price | formatNumber}}</span>
                </template>
                <br/>
			    <b-button variant="success" size="sm" @click="savePrice(result)">
				Save
			    </b-button>
			</template>
		    </td>
		    <td v-if="rules.ceo">{{result.invcog}}</td>
            <td class="text-right">
                <template v-if="result.history_price!=''">
                    <b-button style="width:100%;background-color: #cccccc" @click="showPriceHistory(result)">{{result.history_price | formatNumber}}</b-button>
                </template>
            </td>
		    <td>
			<b-button class="op"
			    :class="{ 'btn-success': result.id != active_lot,  'btn-danger': result.id == active_lot }" 
			    :disabled="ether.readonly || !rule_tv_write || active_lot == result.id || ( ether.fact_from=='' || ether.fact_to!='') ||  (result.time_lot_start!='' && !result.return) || result.final_price == 0"
			    @click="activateLot(result.id, index)"
			>
			    Live
			</b-button>
		    </td>
            <td>
                <b-form-checkbox
                        v-model="result.return"
                        :disabled="( ether.fact_from=='' || ether.fact_to!='') || (result.time_lot_finish!='' && !result.return) || result.final_price == 0"
                        @change="changeReturning(result)"
                >
                </b-form-checkbox>
            </td>
            <td v-if="result.final_price!=0">{{result.pl}}</td>
            <td v-else></td>
		    <td>
                <template v-if="result.child_stock.length > 0">
                    <button class="btn btn-info" :id="'popover-target-'+result.id">{{result.qty_start | formatNumber}}</button>
                    <b-popover :target="'popover-target-'+result.id" triggers="hover" placement="auto">
                        <template #title>Остатки по модификациям:</template>
                        <table class="table scroll-table-body">
                            <tr v-for="(item2, index2) in result.child_stock">
                                <td style="font-size: 80%">{{item2.name}}</td>
                                <td style="font-size: 80%">{{item2.value}}</td>
                            </tr>
                        </table>
                    </b-popover>
                </template>
                <template v-else>
                    {{result.qty_start | formatNumber }}
                </template>
            </td>
		    <td>{{result.qty_reserved | formatNumber }}</td>
		    <td class="text-success"><b>{{result.qty_sold | formatNumber }}</b></td>
		    <td>{{result.qty_left | formatNumber }}</td>
            <td>{{result.qty_hold | formatNumber }}</td>
		    <td v-if="rules.ceo">{{result.margin}}</td>
		    <td v-if="rules.ceo">{{result.callers_total | formatNumber }}</td>
		    <td v-if="rules.ceo">{{result.callers_old | formatNumber }}</td>
		    <td v-if="rules.ceo">{{result.callers_new | formatNumber }}</td>
		    <td v-if="rules.ceo">{{result.calls_total | formatNumber }}</td>
		    <td v-if="rules.ceo">{{result.calls_old | formatNumber }}</td>
		    <td>{{result.calls_new | formatNumber }}</td>
		    <td v-if="rules.ceo">{{result.time_lot}}</td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo"></td>
		    <td>{{result.time_lot_start}}</td>
		    <td>{{result.time_lot_finish}}</td>
            <td class="text-right at-padding">{{(result.final_price*result.qty_sold) | formatNumber}}</td>
            <td class="text-right at-padding">{{result.ppm |formatNumber}}</td>
		    <td>{{result.lotn}}</td>
            <td v-if="rule_tv_write">
                <template v-if="result.id == previous_lot">
                    <b-button variant="danger" size="sm" @click="changePreviousLot(result)">
                        PREV
                    </b-button>
                </template>
                <template v-else>
                    <b-button variant="success" size="sm" @click="changePreviousLot(result)">
                        PREV
                    </b-button>
                </template>
                <template v-if="!ether.show_info_block">
                    <b-button style="margin-top: 5px" variant="info" size="sm" @click="changeShowInfoBlock()">
                        SHOW
                    </b-button>
                </template>
                <template v-else>
                    <b-button style="margin-top: 5px" variant="primary" size="sm" @click="changeShowInfoBlock()">
                        HIDE
                    </b-button>
                </template>
            </td>
		</tr>
		</tbody>
		<tfoot>
		<tr>
		    <td></td>
		    <td></td>
		    <td></td>
		    <td></td>
		    <td></td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rule_tv_admin"></td>
            <td v-if="!rule_supervisor"></td>
		    <td v-if="!rule_supervisor"></td>
		    <td></td>
		    <td v-if="rules.ceo"></td>
            <td></td>
		    <td></td>
            <td></td>
            <td>{{totalPointsLot}}</td>
		    <td>{{totalStart | formatNumber }}</td>
		    <td>0</td>
		    <td class="text-success"><b>{{totalSold | formatNumber }}</b></td>
		    <td>{{totalLeft | formatNumber }}</td>
            <td>{{totalHold | formatNumber }}</td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo">{{totalCallsTotal}}</td>
		    <td v-if="rules.ceo">{{totalCallsOld}}</td>
		    <td>{{totalCallsNew}}</td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo"></td>
		    <td v-if="rules.ceo"></td>
		    <td></td>
		    <td></td>
            <td class="text-right at-padding">{{totalEther| formatNumber}}</td>
            <td></td>
		    <td></td>
            <td v-if="rule_tv_write"></td>
		</tr>
		</tfoot>
	    </table>
	</div>
        <b-modal id="bv-modal-price-history" hide-footer size="lg">
            <template #modal-title>
                {{price_history_title}}
            </template>
            <div class="d-block text-center">
                <table class="table">
                    <tr>
                        <th></th>
                        <th>{{$t('main.broadcast_date')}}</th>
                        <th>{{$t('main.broadcast_number_channel_name')}}</th>
                        <th>{{$t('main.sales')}}</th>
                        <th>{{$t('main.price')}}</th>
                    </tr>
                    <tr v-for="(item, index) in priceHistoryData" :class="{'text-danger': item.future}">
                        <td>
                            <template v-if="item.show==0">
                                <p class="text-danger">No Air</p>
                            </template>
                        </td>
                        <td>{{item.ctime}}</td>
                        <td class="text-left">{{item.ether}}</td>
                        <td>{{item.qty}}</td>
                        <td>{{item.price | formatNumber}}</td>
                    </tr>
                </table>
            </div>
            <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-price-history')">{{$t('main.close')}}</b-button>
        </b-modal>
        <productComment></productComment>
    </div>
</template>

<style>
    table.lots {
	    border: solid 1px rgb(128, 128, 128);
	    width: 100%;
    }

    table.lots th {
	    border-right: solid 1px rgb(128, 128, 128);
	    border-bottom: solid 1px rgb(128, 128, 128);
	    text-align: center;

        background: white;
        position: sticky;
        top: 0; /* Don't forget this, required for the stickiness */
        box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
        z-index:99;
    }

    table.lots td {
	    border-right: solid 1px rgb(128, 128, 128);
	    border-bottom: solid 1px rgb(128, 128, 128);
	    text-align: center;
        padding-left: 3px;
        padding-right: 3px;
    }
    
    button.op {
	    margin: 5px;
    }

    .at-padding {
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-active-ether {
        background-color: #efefef;
        color: black;
    }
</style>
<script>
import Vue from 'vue';
import Axios from 'axios';
import bus from "@/bus";
import ExpandImage from "@/components/ExpandImage.vue";
import productComment from "@/components/productComment.vue";

Vue.filter("formatNumber", function (value) {
  return new Intl.NumberFormat().format(value);
});

export default {
    name: 'Tv',
    components: {
        ExpandImage,
        productComment
    },
    data() {
        return {
	        ether_id: 	0,
	        active_lot:	0,
	        activate_lot_index: -1,
            previous_lot: 0,
            checkedProducts:[],
	        update_price: 0,
	        ethers: [
		        { value: 0, text: this.$t('main.choose_broadcast'), disabled: true }
	        ],
	        ether: {
		        plan_from:	'-',
		        plan_to:	'-',
                fact_from:  '',
                fact_to:    ''
	        },
	        rules: {
		        ceo: false
	        },
	        lots: [],
	        local_ethers: [],
            number_sales: 0,
            number_calls: 0,
	        timer: null,
            price_history_title: '',
            priceHistoryData: []
        }
    },

    metaInfo() {
        return {
            title: this.$t('tv.title')
        }
    },

    mounted() {
        this.ether_id = this.$route.params.id;
        bus.$emit('set-header', this.$t('tv.title'));
        this.loadData();
        this.startUpdate();
    },

    beforeRouteLeave (to, from, next) {
        this.stopUpdate();
        next();
    },
    methods: {
        startUpdate(){
	        let _this = this;
	        if (!_this.timer) {
                this.timer = setInterval(() => {
                    if (!document.hidden
                        && _this.$route.name == 'tv'
                        &&  _this.ether.fact_from!=''
                        && _this.ether.fact_to==''
                        && _this.update_price==0
                    ) _this.loadData();
	            }, 10000);
            }
	    },

        stopUpdate(){
	        if (this.timer) clearInterval(this.timer);
            if (process.env.NODE_ENV !== 'production') console.log('destroy timer tv');
        },
	  
        loadData(){                  // загрузка данных с backend
            let _this = this;
            Axios
                .get(this.prefix_url + '/api/ethers?ts='+Date.now()+"&ether_id="+this.ether_id+'&tz='+Intl.DateTimeFormat().resolvedOptions().timeZone)
                .then(response => {
                    _this.local_ethers = response.data.ethers;
     
    		        let index = this.local_ethers.map(function (item) {
        			    return item.id;
    				}).indexOf(_this.ether_id);

    		        _this.ether = this.local_ethers[index];
                    _this.number_sales = _this.ether.number_sales;
                    _this.number_calls = _this.ether.number_calls;
                    _this.previous_lot = _this.ether.previous_lot;
    		        _this.loadEther(_this.ether_id);
                });
        },
          
        loadEther(ether_id) {
            let _this = this;
            Axios
                .get(this.prefix_url + '/api/lots/'+ether_id+'/?ts='+Date.now()+'&tz='+Intl.DateTimeFormat().resolvedOptions().timeZone)
                .then(response => {
                    _this.lots = response.data.ether;
                    _this.lots.forEach(function(item){
                	    if (item.active_lot > 0) {
                	        _this.active_lot = item.id;
                	        _this.activate_lot_index = item.pos - 1;
                	    }
                        item.final_price    = parseInt(item.final_price);
                        item.min_price      = parseInt(item.min_price);
                        item.target_price   = parseInt(item.target_price);
                	    item.update         = false;
                    });
                });
        },

        start_ether(ether_id) {
    	     let _this = this;
    	     Axios
        	.post(this.prefix_url + '/api/lots/'+ether_id+'/', {
        	    method: 'START_ETHER'
        	})
        	.then(res => {
        	    if (res.data.success) {
        		_this.ether.fact_from = Date.now();
        		_this.loadEther(ether_id);
                    bus.$emit('view-message', { message: this.$t('tv.broadcast_launched'), variant: 'success' });
        	    } else {
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
        	    }
        	}).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
        },

        finish_ether(ether_id) {
    	     let _this = this;
    	     if (confirm(this.$t('main.do_you_really_want_to_end_broadcast'))) {
    		    Axios
        	    .post(this.prefix_url + '/api/lots/'+ether_id+'/', {
        		    method: 'FINISH_ETHER'
        	    }).then(res => {
        		    if (res.data.success) {
        		        _this.ether.fact_to = Date.now();
        		        _this.active_lot = 0;
			            _this.loadEther(ether_id);
                        bus.$emit('view-message', { message: this.$t('tv.broadcast_stopped'), variant: 'success' });
        		    } else {
                        bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
        		    }
        	    }).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
            }
        },

        reload_ether() {
            let _this = this;
            if (confirm(this.$t('tv.do_you_really_want_reinitialize_initial_balances'))) {
                Axios
                    .post(this.prefix_url + '/api/lots/'+this.ether_id+'/', {
                        method:     'RELOAD_ETHER'
                    }).then(res => {
                        if (res.data.success) {
                            bus.$emit('view-message', { message: this.$t('tv.the_stock_of_broadcast_are_overloaded'), variant: 'success' });
                            this.loadData();
                        } else {
                            bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                        }
                }).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
            }
        },

        activateLot(lot_id, index) {
            let old_lot = this.active_lot,
        	    new_lot = lot_id,
        	    _this   = this;

            if (confirm(this.$t('tv.are_you_sure_that_correct_price_set_for_lot_closed'))) {
    		    Axios
        	        .post(this.prefix_url + '/api/lots/'+this.ether_id+'/', {
        		        method: 'CHANGE_LOT',
        		        old: old_lot,
        		        new: new_lot,
        		        ether_id: this.ether_id
        	        }).then(res => {
        		        if (res.data.success) {
			                _this.active_lot = new_lot;
			                _this.activate_lot_index = index;
			                if (old_lot != 0) {
    				            let old_l		= _this.searchLot(old_lot);
//    				            old_l.time_lot		= res.data.time_lot;
    				            old_l.time_lot_finish	= res.data.cur_time;
			                }

    			            let new_l			= _this.searchLot(new_lot);
    			            new_l.time_lot_start	= res.data.cur_time;
                            bus.$emit('view-message', { message: this.$t('tv.new_lot_activated'), variant: 'success' });
        		        } else {
                            bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
        		        }
        	        }).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
            }
        },

	    searchLot(lot_id) {
    	    let index = this.lots.map(function (item) {
        	    return item.id;
    	    }).indexOf(lot_id);
	        return this.lots[index];
	    },

	    minusPrice(item, delta) {
	        let price = item.final_price;
	        if ((price - delta) < 0) item.final_price = 0;
	        else item.final_price = price - delta;
	    },

	    savePrice(item) {
    	     let _this = this;
    	     Axios
        	.post(this.prefix_url + '/api/lots/'+this.ether_id+'/', {
        	    method: 'CHANGE_PRICE',
        	    lot_id: item.id,
        	    price: item.final_price
        	})
        	.then(res => {
        	    if (res.data.success) {
        		_this.update_price = 0;
                    bus.$emit('view-message', { message: this.$t('tv.lot_price_changed'), variant: 'success' });
        	    } else {
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
        	    }
        	}).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
	    },

        changeReturning(item) {
            let _this = this;

            Axios
                .post(this.prefix_url + '/api/lots/'+this.ether_id+'/', {
                    method: 'CHANGE_RETURNING',
                    lot_id: item.id,
                    return: item.return
                })
                .then(res => {
                    if (res.data.success) {

                    }
            }).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
        },

        changePreviousLot(result) {
            console.log(result);
            if (this.previous_lot == result.id) this.previous_lot = 0;
            else this.previous_lot = result.id;

            let _this = this;
            Axios
                .post(this.prefix_url + '/api/lots/'+this.ether_id+'/', {
                    method: 'SET_PREVIOUS_LOT',
                    value:  this.previous_lot
                })
                .then(res => {
                if (res.data.success) {
                    bus.$emit('view-message', { message: res.data.message, variant: 'success' });
                } else {
                    bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                }
            }).catch(error => console.log(error));
        },

        changeShowInfoBlock(){
            this.ether.show_info_block = !this.ether.show_info_block;

            let _this = this;
            Axios
                .post(this.prefix_url + '/api/ethers/'+this.ether_id+'/', {
                    method: 'SHOW_INFO_BLOCK',
                    value:  this.ether.show_info_block
                })
                .then(res => {
                    if (res.data.success) {
                        bus.$emit('view-message', { message: res.data.message, variant: 'success' });
                    } else {
                        bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                    }
            }).catch(error => console.log(error));
        },


        changeFinalPrice(item) {
            if (item.final_price < item.min_price) item.final_price = item.min_price;
        },

// Пока не используется из-за того что лот может поддержать модификации
        saveStock(item) {
            let _this = this;
            Axios
                .post(this.prefix_url + '/api/lots/'+this.ether_id+'/', {
                    method: 'CHANGE_STOCK',
                    lot_id: item.id,
                    stock: item.final_price
                })
                .then(res => {
                    if (res.data.success) {
                        _this.update_price = 0;
                        bus.$emit('view-message', { message: 'Цена лота изменена', variant: 'success' });
                    } else {
                        bus.$emit('view-message', { message: res.data.message, variant: 'danger' });
                    }
                }).catch(error => { if (process.env.NODE_ENV !== 'production') console.log(error)});
        },

        round(value, decimals) {
            return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
        },

        showOfferComment(item){
            bus.$emit('show-offer-comment', item.offer_id);
        },

        showPriceHistory(item){
            this.price_history_title = item.name;
            this.priceHistoryData = item.history;
            this.$bvModal.show('bv-modal-price-history');
        }
    },

    computed: {
	    totalStart() {
    	    let total = 0;
    	    this.lots.forEach(function (item) {
    		total += parseInt(item.qty_start);
    	    });
    	    return total;
	    },

	    totalSold() {
    	    let total = 0;
    	    this.lots.forEach(function (item) {
    		total += parseInt(item.qty_sold);
    	    });
    	    return total;
	    },

	    totalLeft() {
    	    let total = 0;
    	    this.lots.forEach(function (item) {
    		total += parseInt(item.qty_left);
    	    });
    	    return total;
	    },

        totalHold() {
            let total = 0;
            this.lots.forEach(function (item) {
                total += parseInt(item.qty_hold);
            });
            return total;
        },

	    totalEther() {
    	    let total = 0;
    	    this.lots.forEach(function (item) {
    		total += parseFloat(item.qty_sold) * parseFloat(item.final_price);
    	    });
    	    return total;
	    },

        totalCallsNew() {
            let total = 0;
            this.lots.forEach(function (item) {
                total += parseInt(item.calls_new);
            });
            return total;
        },

        totalCallsOld() {
            let total = 0;
            this.lots.forEach(function (item) {
                total += parseInt(item.calls_old);
            });
            return total;
        },

        totalCallsTotal() {
            let total = 0;
            this.lots.forEach(function (item) {
                total += parseInt(item.calls_total);
            });
            return total;
        },

        totalPointsLot(){
            let total1 = 0.0;
            let total2 = 0.0;

            this.lots.forEach(function (item) {
                total1 = total1 + ((parseInt(item.final_price) - parseInt(item.target)) * parseInt(item.qty_sold));
                total2 = total2 + parseInt(item.cog) * parseInt(item.qty_sold);
            });
            if (process.env.NODE_ENV !== 'production') console.log(total1, total2, total1/total2);
            if (total2 == 0) return "-";
            return this.round(total1/(total2/10), 1).toFixed(1);
        },

        flagStartEther(){
            if (typeof this.ether.start_ether === 'undefined') return true;

            let d_start_ether = new Date(this.ether.start_ether),
                    d_cur_time = new Date();

            if (d_cur_time.getTime() >= d_start_ether.getTime()) return true;

            return false;
        },

        flagStopEther(){
            if (this.rule_tv_admin) return true;
            if (typeof this.ether.stop_ether === 'undefined') return true;

            let d_stop_ether = new Date(this.ether.stop_ether),
                    d_cur_time = new Date();

            if (d_cur_time.getTime() >= d_stop_ether.getTime()) return true;

            return false;
        },

        rule_tv_write: function (){return this.$store.getters.getRule('tv_write') },
        rule_tv_admin: function (){return this.$store.getters.getRule('tv_admin') },
        rule_supervisor: function (){return this.$store.getters.getRule('supervisor') },

        currency: function () {
            let loc = this.$store.getters.getLocal;
            return loc.currency;
        },

        prefix_url:function () { return process.env.VUE_APP_PREFIX_URL }

    }
}
</script>
